::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #282828;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }